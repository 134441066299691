.title {
  flex-grow: 1;
  text-align: left;
}

.avatarContainer {
  margin-left: 8px;
}

.header-logo {
  height: 80px;
}
