.multi-line-header .MuiDataGrid-columnHeaderTitle {
  white-space: normal;
  line-height: 1.5;
}

.MuiDataGrid-root {
  background-color: #fefdfa;
}

.MuiDataGrid-columnHeader {
  height: 100px !important;
}

.standardization-header {
  background-color: #033a8838;
}

.automation-header {
  background-color: #ff55605e;
}

.required-placeholder {
  color: red;
  font-weight: bold;
}