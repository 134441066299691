body {
  background-image: url("./assets/gen_pic.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
  /* Standard syntax (linear gradient)
  background: linear-gradient(to right, #0a0c48, #1f129c);

  /* Vendor prefixes for wider browser support */
  /* background: -webkit-linear-gradient(to right, #0a0c48, #1f129c);
  background: -moz-linear-gradient(to right, #0a0c48, #1f129c);
  background: -o-linear-gradient(to right, #0a0c48, #1f129c);
  background: linear-gradient(to right, #0a0c48, #1f129c); */
}

.white-background {
  background-color: #fff;
  border-radius: 5px;
}

.flex-grow {
  flex-grow: 1;
}

.pop-icon {
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: #FFFFFF;
  border-radius: 4px;
  /* padding-left: 5px; */
}