.filter-container {
  position: fixed;
  bottom: 10%;
  right: 0;
  width: 50%; /* 50% of the viewport width */
  height: 50%;
}

.main-grid {
  position: fixed;
  left: 0;
  margin-top: 2%;
  text-align: right;
  width: 100%;
  padding-right: 3.5%;
}
