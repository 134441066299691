.login-label {
  padding-right: 8%;
  padding-top: 1%;
}

.main-login-div {
  height: 85vh;
  display: flex;
  justify-content: center;
}

.container-login-div {
  width: 60% !important;
  height: 40%;
  border: 4px solid #404040a2;
  background-color: #606060a2;
  border-radius: 50px;
  justify-content: center;
  margin-top: 5%;
  padding-top: 3%;
}

.center {
  text-align: end;
}

.login-input {
  background-color: #fff;
  width: 250px ;
  border-radius: 5px;
}